<template>
  <TableWidgetStyled>
    <TableV2
      :title="$t('widgetTypes.TIMELINE_CHART')"
      :headers="headers"
      :rows="rows"
      :sorting.sync="sorting"
      :filtersAvailable="filtersAvailable"
      :filterOptionsSelected="filterOptionsSelected"
      :isLoading="isLoading"
      :searchQuery="searchQuery"
      :enableSearch="true"
      :isMobile="isMobile"
      :enableFooter="true"
      :messages="messages"
      @tableExport="tableExport"
      @textFilter="textSearch"
    />
  </TableWidgetStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { partition, concat, orderBy, compact } from 'lodash'
import units, { resolveUnitUI } from '@/utils/units'
import { exportTableDataToCSV } from '@/utils/export'
import { formatDateAccordingToSettings } from '@/utils/time'
import localesMixin from '@/mixins/locales'
import TableV2 from '@/components/Atomic/Molecules/TableV2.vue'
import { TABLE_SORT_TYPES } from '@/constants/settings'

export const TableWidgetStyled = styled('div')`
  width: 100%;
  height: 100%;
  color: ${({ theme }) => theme.colors.navFontNormal};
  overflow: auto;
`
export default {
  inject: ['uiSettings'],
  mixins: [localesMixin],
  props: {
    activities: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
      required: false,
    },
    isMobile: {
      type: Boolean,
      required: false,
      default: false,
    },
    tz: {
      type: String,
      default: 'local',
    },
    isSingleAssetView: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  components: {
    TableV2,
    TableWidgetStyled,
  },
  data() {
    return {
      searchQuery: '',
      sorting: {
        key: 'start',
        asc: true,
      },
      filterOptionsSelected: [],
      filtersAvailable: [],
    }
  },
  computed: {
    headers() {
      const headers = [
        {
          id: `header_start`,
          label: this.$t('activityTable.start'),
          sortType: TABLE_SORT_TYPES.NEW_TO_OLD,
          isSortable: true,
          sortKey: 'start',
          size: 'medium',
        },
        {
          id: `header_end`,
          label: this.$t('activityTable.end'),
          sortType: TABLE_SORT_TYPES.NEW_TO_OLD,
          isSortable: true,
          sortKey: 'end',
          size: 'medium',
        },
        {
          id: `header_type`,
          label: this.$t('activityTable.type'),
          sortType: TABLE_SORT_TYPES.A_TO_Z,
          isSortable: true,
          sortKey: 'type',
          size: 'medium',
        },
        {
          id: `header_duration`,
          label: this.$t('activityTable.duration'),
          sortType: TABLE_SORT_TYPES.LOW_TO_HIGH,
          isSortable: true,
          sortKey: 'duration',
          size: 'medium',
        },
      ]
      return headers
    },

    activitiesSorted() {
      const partitions = partition(this.activities, x => !!(x?.[this.sorting.key] ?? null))
      const sortDirection =
        this.sorting.key === 'start' || this.sorting.key === 'end' ? (this.sorting.asc ? 'desc' : 'asc') : this.sorting.asc ? 'asc' : 'desc'
      return concat(orderBy(partitions[0], [this.sorting.key], [sortDirection]), partitions[1])
    },
    rows() {
      const rows = this.activitiesSorted.map((item, rowIndex) => {
        const unitUI = resolveUnitUI(this.selectedUIUnitSystem, 'minutes')
        let cells = [
          {
            id: `${item.asset}_start`,
            sortableValue: formatDateAccordingToSettings(item.start, this.uiSettings, this.tz),
            mobilePrimarycolLabel: formatDateAccordingToSettings(item.start, this.uiSettings, this.tz),
            isMobilePrimarycol: true,
            headerId: `header_start`,
          },
          {
            id: `${item.asset}_end`,
            sortableValue: formatDateAccordingToSettings(item.end, this.uiSettings, this.tz),
            headerId: `header_end`,
          },
          {
            id: `${item.asset}_type`,
            sortableValue: this.$t(`activityTypes.${item.type}`),
            headerId: `header_type`,
          },
          {
            id: `${item.asset}_duration`,
            sortableValue:
              item.duration !== null
                ? units(item.duration, 's', unitUI, 2, false, true, false, this.thousandsSeperator, this.decimalSeperator, true)
                : '-',
            headerId: `header_duration`,
          },
        ]
        return {
          rowId: `row_${rowIndex}`,
          cells,
        }
      })

      const filteredTable = rows.filter(row => {
        const props = compact(row.cells.map(item => item.sortableValue))
        return props.some(
          prop =>
            !this.searchQuery ||
            (typeof prop === 'string'
              ? prop.toLowerCase().includes(this.searchQuery.toLowerCase())
              : prop.toString(10).includes(this.searchQuery)),
        )
      })
      return filteredTable
    },
    messages() {
      let message = ''
      if (this.rows.length === 0) {
        if (!this.isSingleAssetView) {
          message = this.$tc('messages.incompatibleDashboardType')
        } else {
          const dimensionName = this.$t('activity')
          message =
            this.searchQuery === ''
              ? this.isOneDaySelected
                ? this.$t('messages.noDimensionData', { dimension: dimensionName })
                : this.$tc('messages.selectOneDay')
              : this.$t('messages.searchNoResults', { query: this.searchQuery })
        }
      }
      return message
    },
  },
  methods: {
    tableExport(type) {
      if (type === 'csv') {
        const mappedHeaders = this.headers.map(header => ({ data: header.label }))
        const mappedRows = this.rows.flatMap(header => ({ data: header.cells })).flatMap(item => item.data)
        exportTableDataToCSV(mappedHeaders, mappedRows, 'talpa-export_activity_table')
      }
    },
    textSearch(query) {
      this.searchQuery = query
    },
  },
}
</script>
