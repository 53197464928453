<template>
  <TimelineChartIntervalStyled>
    <header>
      <div class="label">
        {{ timeLineStartinTZ(timeline.interval.start) }}
      </div>
      <div v-if="totalOnlineTime.val > 0">
        &Sigma; {{ totalOnlineTime.val | round(1, true) | numberLocalized }} {{ totalOnlineTime.unit }} /
        {{ totalIntervalTime.val | round(1, true) | numberLocalized }} {{ totalIntervalTime.unit }}
      </div>
      <div v-else>&Sigma; 0 s</div>
    </header>
    <div class="bar">
      <template v-if="showDetails">
        <Segment
          v-for="(segment, i) in segments"
          :key="segment.id + '_segment_' + i"
          :segment="segment"
          :useActivityColors="useActivityColors"
        />
      </template>
      <template v-else>
        <ActivityTypePercentage v-for="(activity, i) in activitySumsByType" :key="activity.id + '_percentage_' + i" :activity="activity" />
      </template>
    </div>
    <div class="ticks">
      <TickStyled v-for="(tick, i) in ticks" :key="'_tick_' + i">
        {{ tick }}
      </TickStyled>
    </div>
    <div class="types">
      <ActivityType v-for="(activity, i) in activitySumsByType" :key="activity.id + '_type_' + i" :activity="activity" />
    </div>
  </TimelineChartIntervalStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import get from 'lodash/get'
import sumBy from 'lodash/sumBy'
import chroma from 'chroma-js'

import convert from '@common/convert-units'

import { DateTime, Duration } from 'luxon'

import { dateTimeByGranularity, timeSimple } from '@/utils/filters/time'

import round from '@/utils/round'
import numberLocalized from '@/utils/number-localized'

import Segment from './TimelineChartInterval/Segment'
import ActivityType from './TimelineChartInterval/ActivityType'
import ActivityTypePercentage from './TimelineChartInterval/ActivityTypePercentage'

import { flexColumns, flexCenter, flexStartCenter } from '@styles/mixins'

const TimelineChartIntervalStyled = styled('div')`
  ${flexColumns}
  margin: 0 .5rem;
  margin-top: 1.5rem;
  &:first-child {
    margin-top: 0.5rem;
  }
  &:last-child {
    margin-bottom: 0.5rem;
  }
  header {
    ${flexCenter}
    font-size: 14px;
    justify-content: space-between;
  }
  .bar {
    ${flexCenter}
    cursor: pointer;
    position: relative;
    margin: 0.25rem 0rem;
    height: 1.5rem;
    background: ${({ theme }) => chroma(theme.colors.black).alpha(1).css()};
    transition: background-color 0.25s ease;
    color: ${p => p.theme.colors.atomic.white};
    &:hover {
      background: ${({ theme }) => chroma(theme.colors.black).alpha(0.8).css()};
    }
  }
  .ticks {
    ${flexStartCenter};
    justify-content: space-between;
  }
  .types {
    ${flexStartCenter};
    margin-top: 0.5rem;
    justify-content: space-between;
  }
`

const TickStyled = styled('div')`
  ${flexCenter}
  font-size: 12px;
`

export default {
  inject: ['uiSettings'],
  props: {
    timeline: {
      type: Object,
      required: true,
    },
    granularity: {
      type: String,
      required: true,
    },
    useActivityColors: {
      type: Boolean,
      required: true,
    },
    showDetails: {
      type: Boolean,
      required: true,
    },
    machineOffSelection: {
      type: Object,
      require: true,
    },
    tz: {
      type: String,
      default: 'local',
    },
  },
  components: {
    TimelineChartIntervalStyled,
    Segment,
    ActivityTypePercentage,
    ActivityType,
    TickStyled,
  },
  filters: {
    dateTimeByGranularity,
    timeSimple,
    round,
    numberLocalized,
  },
  computed: {
    locale() {
      return get(this.uiSettings, 'dates', 'DE_DE').toLowerCase().replace('_', '-')
    },
    activities() {
      return get(this.timeline, 'activities', [])
    },
    activitySumsByType() {
      const sums = get(this.timeline, 'activities', []).reduce((acc, item) => {
        if (!acc[item.type]) {
          acc[item.type] = {
            id: item.type,
            type: item.type,
            label: this.$t(`activityTypes.${item.type}`),
            duration: item.duration,
          }
        } else {
          acc[item.type].duration += item.duration
        }
        return acc
      }, {})
      const intervalInSeconds = this.maxDuration
      const totalTimeToConsider = this.machineOffSelection.include ? this.maxDuration : this.totalOnlineTimeInSeconds
      const result = Object.keys(sums)
        .sort()
        .map(key => ({
          ...sums[key],
          // to avoid NaN, in case of duration is 0 and totalOnlineTimeInSeconds is also 0
          percent: sums[key].duration / totalTimeToConsider ? sums[key].duration / totalTimeToConsider : 0,
        }))

      if (this.machineOffSelection.include) {
        result.push({
          id: 'NO_DATA',
          type: 'NO_DATA',
          label: this.$t(`activityTypes.NO_DATA`),
          duration: intervalInSeconds - this.totalOnlineTimeInSeconds,
          percent:
            (intervalInSeconds - this.totalOnlineTimeInSeconds) / intervalInSeconds
              ? (intervalInSeconds - this.totalOnlineTimeInSeconds) / intervalInSeconds
              : 0,
        })
      }

      return result
    },
    totalOnlineTimeInSeconds() {
      return sumBy(this.activities, 'duration')
    },
    totalOnlineTime() {
      return convert(this.totalOnlineTimeInSeconds).from('s').toBest()
    },
    totalIntervalTime() {
      if (this.timeline.interval.length('seconds') > 3600) {
        return convert(this.timeline.interval.length('seconds'))
          .from('s')
          .toBest({ exclude: ['d'] })
      }
      return convert(Math.ceil(this.timeline.interval.length('seconds')))
        .from('s')
        .toBest()
    },
    maxDuration() {
      return this.timeline.interval.length('seconds')
    },
    ticks() {
      let result = []

      if (this.showDetails) {
        const ms =
          this.granularity === 'P1D'
            ? Math.floor(Duration.fromISO(this.granularity).valueOf() / 8)
            : Math.floor(Duration.fromISO(this.granularity).valueOf() / 6)
        const splits =
          this.granularity === 'P1D'
            ? this.timeline.interval.splitBy(ms).map(iv => iv.start.startOf('hour'))
            : this.timeline.interval.splitBy(ms).map(iv => iv.start.startOf('minute'))
        splits.push(this.timeline.interval.end)
        result = splits.map(i => timeSimple(i.setZone(this.tz), this.locale))
      } else {
        result = ['0%', '100%']
      }

      return result
    },
    segments() {
      return this.activities.map(a => {
        const offsetSeconds = Math.max(0, DateTime.fromISO(a.start).diff(this.timeline.interval.start).as('seconds'))
        const offset = offsetSeconds ? (offsetSeconds / this.maxDuration) * 100 : 0
        const segment = {
          ...a,
          id: a.start,
          percent: a.duration ? (a.duration / this.maxDuration) * 100 : 0,
          offset,
        }
        return segment
      })
    },
  },
  methods: {
    toggleShowDetails() {
      this.$emit('toggle-show-details')
    },
    timeLineStartinTZ(start) {
      return dateTimeByGranularity(start.setZone(this.tz), this.locale, this.granularity)
    },
  },
}
</script>
