<template>
  <AssetDimensionTimeLineChartWidget
    :asset="this.assetStore.assetsSelected()"
    :isSingleAssetView="this.assetStore.isSingleAssetView"
    :selectedUIUnitSystem="selectedUIUnitSystem"
    :isMobile="isMobile"
  />
</template>

<script>
import AssetDimensionTimeLineChartWidget from '@/components/Atomic/Organisms/Dashboard/AssetDimensionTimeLineChartWidget'
import localesMixin from '@/mixins/locales'
import { TimeframeMixin } from '@common/mixins'
import resizeMixin from '@/mixins/resize'
import { get } from 'lodash'
import { useAssetStore } from '@/stores/assets'

export default {
  inject: ['theme', 'uiSettings'],
  mixins: [TimeframeMixin, localesMixin, resizeMixin],
  setup() {
    const assetStore = useAssetStore()
    return {
      assetStore,
    }
  },
  props: {
    widget: {
      type: Object,
      required: false,
    },
    title: {
      type: String,
    },
    pdfData: {
      type: Object,
      required: false,
    },
  },
  components: {
    AssetDimensionTimeLineChartWidget,
  },
  data() {
    return {
      isMobile: true,
    }
  },
  methods: {
    gridItemResized() {
      this.handleResize()
    },
    handleResize() {
      this.$nextTick(() => {
        const size = get(this.$el, 'clientWidth', 96)
        const isMobile = size < 600 && size >= 96
        this.isMobile = isMobile || (this.isPreview && this.isMobilePreview)
      })
    },
  },
}
</script>
