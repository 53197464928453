var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('TimelineChartIntervalStyled', [_c('header', [_c('div', {
    staticClass: "label"
  }, [_vm._v(" " + _vm._s(_vm.timeLineStartinTZ(_vm.timeline.interval.start)) + " ")]), _vm.totalOnlineTime.val > 0 ? _c('div', [_vm._v(" Σ " + _vm._s(_vm._f("numberLocalized")(_vm._f("round")(_vm.totalOnlineTime.val, 1, true))) + " " + _vm._s(_vm.totalOnlineTime.unit) + " / " + _vm._s(_vm._f("numberLocalized")(_vm._f("round")(_vm.totalIntervalTime.val, 1, true))) + " " + _vm._s(_vm.totalIntervalTime.unit) + " ")]) : _c('div', [_vm._v("Σ 0 s")])]), _c('div', {
    staticClass: "bar"
  }, [_vm.showDetails ? _vm._l(_vm.segments, function (segment, i) {
    return _c('Segment', {
      key: segment.id + '_segment_' + i,
      attrs: {
        "segment": segment,
        "useActivityColors": _vm.useActivityColors
      }
    });
  }) : _vm._l(_vm.activitySumsByType, function (activity, i) {
    return _c('ActivityTypePercentage', {
      key: activity.id + '_percentage_' + i,
      attrs: {
        "activity": activity
      }
    });
  })], 2), _c('div', {
    staticClass: "ticks"
  }, _vm._l(_vm.ticks, function (tick, i) {
    return _c('TickStyled', {
      key: '_tick_' + i
    }, [_vm._v(" " + _vm._s(tick) + " ")]);
  }), 1), _c('div', {
    staticClass: "types"
  }, _vm._l(_vm.activitySumsByType, function (activity, i) {
    return _c('ActivityType', {
      key: activity.id + '_type_' + i,
      attrs: {
        "activity": activity
      }
    });
  }), 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }