<template>
  <ActivityTypeStyled :percent="activity.percent" :color="color" :type="activity.type">
    <template v-if="activity.percent > 0.2"> {{ duration.val | round(true) | numberLocalized }} {{ duration.unit }} </template>
  </ActivityTypeStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

import { ActivityColors } from '@styles/themes'

import round from '@/utils/round'
import numberLocalized from '@/utils/number-localized'
import convert from '@common/convert-units'

import { flexCenter } from '@styles/mixins'

const ActivityTypeStyled = styled('div')`
  ${flexCenter}
  width: ${p => p.percent * 100}%;
  background: ${p => p.color};
  overflow: hidden;
  height: 1.5rem;
  text-overflow: ellipsis;
`

export default {
  props: {
    activity: {
      type: Object,
      required: true,
    },
  },
  components: {
    ActivityTypeStyled,
  },
  filters: {
    round,
    numberLocalized,
  },
  computed: {
    duration() {
      // return units(this.activity.duration, 's', 'h', 1, true)
      return convert(this.activity.duration).from('s').toBest()
    },
    tooltip() {
      return `${this.activity.label} &middot; ${round(this.duration.val)} ${this.duration.unit}`
    },
    color() {
      return ActivityColors[this.activity.type]
    },
  },
}
</script>
