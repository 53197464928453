<template>
  <SegmentStyled :percent="segment.percent" :offset="segment.offset" :color="color" />
</template>

<script>
import { styled } from '@egoist/vue-emotion'

import { ActivityColors } from '@styles/themes'

const SegmentStyled = styled('div')`
  position: absolute;
  width: ${p => p.percent}%;
  left: ${p => p.offset}%;
  height: 100%;
  background: ${p => p.color};
`

export default {
  inject: ['theme'],
  props: {
    segment: {
      type: Object,
      required: true,
    },
    useActivityColors: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    SegmentStyled,
  },
  computed: {
    color() {
      if (this.useActivityColors) {
        return ActivityColors[this.segment.type]
      }
      return this.theme.colors.charts.first
    },
  },
}
</script>
