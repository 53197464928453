<template>
  <ActivityTypeStyled>
    <AcitivityColorStyled :color="color" />
    <span
      >{{ activity.label }} &middot; {{ (activity.percent * 100) | round(true) | numberLocalized }}% &middot;
      {{ duration.val | round(true) | numberLocalized }} {{ duration.unit }}</span
    >
  </ActivityTypeStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { ActivityColors } from '@styles/themes'
import convert from '@common/convert-units'
import round from '@/utils/round'
import numberLocalized from '@/utils/number-localized'

import { flexCenter } from '@styles/mixins'

const ActivityTypeStyled = styled('div')`
  ${flexCenter}
  font-size: 12px;
`

const AcitivityColorStyled = styled('div')`
  flex-shrink: 0;
  width: 12px;
  height: 12px;
  background: ${p => p.color};
  border-radius: 50%;
  margin-left: 1.5rem;
  margin-right: 0.5rem;
`

export default {
  props: {
    activity: {
      type: Object,
      required: true,
    },
  },
  components: {
    ActivityTypeStyled,
    AcitivityColorStyled,
  },
  filters: {
    round,
    numberLocalized,
  },
  computed: {
    duration() {
      const { val, unit } = convert(this.activity.duration).from('s').toBest()
      return {
        val,
        unit: val === 0 ? 's' : unit,
      }
    },
    tooltip() {
      return `${this.activity.label} &middot; ${this.duration}`
    },
    color() {
      return ActivityColors[this.activity.type]
    },
  },
}
</script>
