var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AssetDimensionTimeLineChartWidget', {
    attrs: {
      "asset": this.assetStore.assetsSelected(),
      "isSingleAssetView": this.assetStore.isSingleAssetView,
      "selectedUIUnitSystem": _vm.selectedUIUnitSystem,
      "isMobile": _vm.isMobile
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }