var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.$apollo.queries.activities.loading || !_vm.assetIds.length ? _c('TalpaLoaderWrapper') : _c('TimelineChartStyled', {
    attrs: {
      "showHeader": _vm.viewMode === 'timeline' || !_vm.activitiesData.length
    }
  }, [_c('header', [_vm.viewMode === 'timeline' || !_vm.activitiesData.length ? _c('WidgetHeaderMolecule', {
    staticClass: "title",
    attrs: {
      "title": _vm.$t('widgetTypes.TIMELINE_CHART'),
      "assetDimensions": []
    }
  }) : _vm._e(), _vm.isSingleAssetView ? _c('div', {
    staticClass: "actions"
  }, [_vm.viewMode !== 'table' ? _c('CustomSingleSelect', {
    staticClass: "select",
    attrs: {
      "options": _vm.dropDownSelectors,
      "filter": _vm.dropDownOptionSelected,
      "closeOnSelect": true,
      "customOptions": 'timelinechart.',
      "customLabels": 'timelinechart.',
      "selectedOption": _vm.preselectedDropDownOption
    },
    on: {
      "selectedFilter": _vm.updateDropDownSelection
    }
  }) : _vm._e(), _c('Row', [_c('ButtonStyled', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: _vm.tooltip('filterEmpty'),
      expression: "tooltip('filterEmpty')"
    }],
    attrs: {
      "active": _vm.filterEmpty,
      "disabled": _vm.viewMode === 'table'
    },
    on: {
      "click": _vm.toggleFilterEmpty
    }
  }, [_c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_c('FilterEmptyIcon', {
    class: {
      active: _vm.filterEmpty
    }
  })], 1)], 1), _c('ButtonStyled', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: _vm.tooltip('showDetails'),
      expression: "tooltip('showDetails')"
    }],
    attrs: {
      "active": _vm.showDetails,
      "disabled": _vm.viewMode === 'table'
    },
    on: {
      "click": _vm.toggleShowDetails
    }
  }, [_c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_c('ShowDetailsIcon', {
    class: {
      active: _vm.showDetails
    }
  })], 1)], 1)], 1), _c('Row', [_vm.isSelectedTimeRangeOneDay ? _c('ButtonStyled', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: _vm.tooltip('tableView'),
      expression: "tooltip('tableView')"
    }],
    attrs: {
      "active": _vm.viewMode === 'table'
    },
    on: {
      "click": function click($event) {
        return _vm.selectViewMode('table');
      }
    }
  }, [_c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_c('TableIcon', {
    class: {
      active: _vm.viewMode === 'table'
    }
  })], 1)], 1) : _vm._e(), _c('ButtonStyled', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: _vm.tooltip('chartView'),
      expression: "tooltip('chartView')"
    }],
    attrs: {
      "active": _vm.viewMode === 'timeline'
    },
    on: {
      "click": function click($event) {
        return _vm.selectViewMode('timeline');
      }
    }
  }, [_c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_c('TimelineChartIcon', {
    class: {
      active: _vm.viewMode === 'timeline'
    }
  })], 1)], 1)], 1)], 1) : _vm._e()], 1), _c('transition-group', {
    staticClass: "intervals",
    attrs: {
      "name": "fade-in-left",
      "tag": "div"
    }
  }, [!_vm.activitiesData.length ? _c('NoDataBlockStyled', {
    key: "no-data"
  }, [_vm._v(" " + _vm._s(_vm.message) + " ")]) : [_vm.viewMode === 'table' ? _c('TimeLineTable', {
    key: "table",
    attrs: {
      "isLoading": _vm.$apollo.queries.activities.loading,
      "activities": _vm.activities,
      "isMobile": _vm.isMobile,
      "tz": _vm.selectedTimezone,
      "isSingleAssetView": _vm.isSingleAssetView
    }
  }) : _vm._e(), _vm.viewMode === 'timeline' ? _c('div', {
    key: "timeline"
  }, _vm._l(_vm.timelines, function (timeline, i) {
    return _c('TimelineChart', {
      key: timeline.id + '_timeline_' + i,
      attrs: {
        "timeline": timeline,
        "granularity": _vm.granularity,
        "useActivityColors": _vm.useActivityColors,
        "showDetails": _vm.showDetails,
        "machineOffSelection": _vm.dropDownOptionSelected,
        "tz": _vm.selectedTimezone
      },
      on: {
        "toggle-show-details": _vm.toggleShowDetails
      }
    });
  }), 1) : _vm._e()]], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }