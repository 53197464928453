var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ActivityTypeStyled', [_c('AcitivityColorStyled', {
    attrs: {
      "color": _vm.color
    }
  }), _c('span', [_vm._v(_vm._s(_vm.activity.label) + " · " + _vm._s(_vm._f("numberLocalized")(_vm._f("round")(_vm.activity.percent * 100, true))) + "% · " + _vm._s(_vm._f("numberLocalized")(_vm._f("round")(_vm.duration.val, true))) + " " + _vm._s(_vm.duration.unit))])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }