var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('SegmentStyled', {
    attrs: {
      "percent": _vm.segment.percent,
      "offset": _vm.segment.offset,
      "color": _vm.color
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }