<template>
  <TalpaLoaderWrapper v-if="$apollo.queries.activities.loading || !assetIds.length" />
  <TimelineChartStyled v-else :showHeader="viewMode === 'timeline' || !activitiesData.length">
    <header>
      <WidgetHeaderMolecule
        :title="$t('widgetTypes.TIMELINE_CHART')"
        :assetDimensions="[]"
        class="title"
        v-if="viewMode === 'timeline' || !activitiesData.length"
      />
      <div class="actions" v-if="isSingleAssetView">
        <CustomSingleSelect
          :options="dropDownSelectors"
          :filter="dropDownOptionSelected"
          :closeOnSelect="true"
          :customOptions="'timelinechart.'"
          :customLabels="'timelinechart.'"
          @selectedFilter="updateDropDownSelection"
          :selectedOption="preselectedDropDownOption"
          class="select"
          v-if="viewMode !== 'table'"
        >
        </CustomSingleSelect>
        <Row>
          <ButtonStyled
            :active="filterEmpty"
            :disabled="viewMode === 'table'"
            @click="toggleFilterEmpty"
            v-tooltip="tooltip('filterEmpty')"
          >
            <transition name="fade">
              <FilterEmptyIcon :class="{ active: filterEmpty }" />
            </transition>
          </ButtonStyled>
          <ButtonStyled
            :active="showDetails"
            :disabled="viewMode === 'table'"
            @click="toggleShowDetails"
            v-tooltip="tooltip('showDetails')"
          >
            <transition name="fade">
              <ShowDetailsIcon :class="{ active: showDetails }" />
            </transition>
          </ButtonStyled>
        </Row>
        <Row>
          <ButtonStyled
            :active="viewMode === 'table'"
            v-if="isSelectedTimeRangeOneDay"
            @click="selectViewMode('table')"
            v-tooltip="tooltip('tableView')"
          >
            <transition name="fade">
              <TableIcon :class="{ active: viewMode === 'table' }" />
            </transition>
          </ButtonStyled>
          <ButtonStyled :active="viewMode === 'timeline'" @click="selectViewMode('timeline')" v-tooltip="tooltip('chartView')">
            <transition name="fade">
              <TimelineChartIcon :class="{ active: viewMode === 'timeline' }" />
            </transition>
          </ButtonStyled>
        </Row>
      </div>
    </header>
    <transition-group name="fade-in-left" class="intervals" tag="div">
      <NoDataBlockStyled v-if="!activitiesData.length" key="no-data">
        {{ message }}
      </NoDataBlockStyled>
      <template v-else>
        <TimeLineTable
          key="table"
          v-if="viewMode === 'table'"
          :isLoading="$apollo.queries.activities.loading"
          :activities="activities"
          :isMobile="isMobile"
          :tz="selectedTimezone"
          :isSingleAssetView="isSingleAssetView"
        />
        <div key="timeline" v-if="viewMode === 'timeline'">
          <TimelineChart
            v-for="(timeline, i) in timelines"
            :key="timeline.id + '_timeline_' + i"
            :timeline="timeline"
            :granularity="granularity"
            :useActivityColors="useActivityColors"
            :showDetails="showDetails"
            @toggle-show-details="toggleShowDetails"
            :machineOffSelection="dropDownOptionSelected"
            :tz="selectedTimezone"
          />
        </div>
      </template>
    </transition-group>
  </TimelineChartStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

import { TalpaLoaderWrapper } from '@common/components'
import { getScheduleOccurencesInInterval } from '@common/utils'

import { Interval, DateTime, Duration } from 'luxon'

import ShowDetailsIcon from '@/assets/icons/showDetail.svg'
import FilterEmptyIcon from '@/assets/icons/removeEmpty.svg'
import TableIcon from '@common/components/src/TalpaIcons/WidgetTypes/TableIcon.svg'
import TimelineChartIcon from '@common/components/src/TalpaIcons/WidgetTypes/TimelineChartIcon.svg'
import { CustomSingleSelect } from '@common/components'
import { flexCenter, flexColumns, flexStartCenter } from '@styles/mixins'
import { ButtonSolid as ButtonSolidStyled } from '@styles/buttons'
import { uniq } from 'lodash'
import { TimeframeMixin } from '@common/mixins'
import TimeLineTable from '@/components/Atomic/Molecules/TimeLineWidget/TimeLineTableMolecule'
import TimelineChart from '@/components/Atomic/Molecules/TimeLineWidget/TimeLineChartMolecule'
import { VTooltip } from 'v-tooltip'
import WidgetHeaderMolecule from '@/components/Atomic/Molecules/WidgetHeaderMolecule'

import ASSET_ACTIVITIES_QUERY from '#/graphql/assetDimensions/activities.gql'

const TimelineChartStyled = styled('div')`
  ${flexColumns}
  width: 100%;
  height: 100%;
  overflow: auto;
  > header {
    ${({ showHeader }) =>
      showHeader &&
      `display:flex;    flex-direction: row;
    justify-content: space-between;`};
    > .actions {
      ${flexStartCenter}
      justify-content: flex-end;
      position: sticky;
      top: 0;
      background: ${p => p.theme.colors.solidBG};
      z-index: 2;
      gap: 10px;
      .active {
        path {
          fill: ${p => (p.theme.isDark ? 'orange' : 'blue')};
        }
      }
      .select {
        background: ${p => p.theme.colors.divBackground};
      }
    }
  }
  .intervals {
    width: 100%;
    height: 100%;
    ${flexColumns}
    overflow: auto;
  }
`

const NoDataBlockStyled = styled('div')`
  ${flexCenter}
  height: 100%;
  padding: 0.5rem;
  @media (min-width: 768px) {
    padding: 2rem;
  }
`

const Row = styled('div')`
  display: flex;

  button {
    width: 40px;
    height: 40px;
  }
`

const ButtonStyled = styled(ButtonSolidStyled)`
  padding: 0;
  margin: 0;
  border-radius: 0;
  background: ${p => p.theme.colors.divBackground};
  svg {
    width: 20px;
    height: 20px;
  }

  path {
    fill: grey;
  }
`

export default {
  mixins: [TimeframeMixin],
  props: {
    asset: {
      type: Array,
      required: true,
    },
    selectedUIUnitSystem: {
      type: String,
      required: true,
    },
    isMobile: {
      type: Boolean,
      required: false,
      default: false,
    },
    isSingleAssetView: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  directives: {
    tooltip: VTooltip,
  },
  components: {
    TimelineChartStyled,
    TimelineChart,
    ButtonStyled,
    TalpaLoaderWrapper,
    TimelineChartIcon,
    TableIcon,
    ShowDetailsIcon,
    FilterEmptyIcon,
    Row,
    NoDataBlockStyled,
    CustomSingleSelect,
    TimeLineTable,
    WidgetHeaderMolecule,
  },
  data() {
    return {
      filterEmpty: true,
      showDetails: false,
      useActivityColors: true,
      viewMode: 'timeline',
      preselectedDropDownOption: {},
      dropDownOptionSelected: null,
    }
  },
  created() {
    if (!this.dropDownOptionSelected) {
      this.dropDownOptionSelected = this.dropDownSelectors[0]
      this.preselectedDropDownOption = this.dropDownSelectors[0]
    }
  },
  computed: {
    assetIds() {
      return this.asset.map(({ id }) => id)
    },
    realTimebuckets() {
      const i = this.selectedInterval
      if (!i || i.invalid || !this.granularity) {
        return []
      }
      const granularity = Duration.fromISO(this.granularity)

      if (this.shiftsEnabled && this.granularity === 'PT1H') {
        const selectedShifts = this.selectedShifts || []
        const newIntv = selectedShifts
          .reduce((acc, schedule) => {
            return acc.concat(getScheduleOccurencesInInterval(schedule, this.selectedInterval))
          }, [])
          .reduce((acc, occurrence) => {
            const duration = Duration.fromMillis(occurrence.duration)
            const start = occurrence.date
            const end = occurrence.date.plus(duration)
            const occuranceIntervals = Interval.fromDateTimes(start, end)
              .splitBy(granularity)
              .map(item => {
                // retriving just the start times of each granularity of the shift, wrt to local, shreading all the timezone details
                return item.toISO()
              })
            return acc.concat(occuranceIntervals)
          }, [])
        return uniq(newIntv.sort()).map(i => Interval.fromISO(i))
      }

      return i.splitBy(granularity)
    },
    isSelectedTimeRangeOneDay() {
      const interval = this.selectedInterval
      if (!interval) {
        return false
      }
      return interval.length('days') <= 1
    },
    activitiesData() {
      return this.activities ? this.activities : []
    },
    timelines() {
      if (this.$apollo.queries.activities.loading) {
        return []
      }
      const timebuckets = {}
      this.realTimebuckets.forEach(iv => {
        timebuckets[iv.start.setZone(this.selectedTimezone).startOf('hour').toISO()] = {
          interval: iv,
          id: iv.toISO(),
          activities: [],
        }
      })
      this.activitiesData.forEach(activity => {
        const startOf = this.granularity === 'PT1H' ? 'hour' : 'day'
        let startISO = DateTime.fromISO(activity.start).setZone(this.selectedTimezone, { keepLocalTime: false }).startOf(startOf).toISO()
        const activityInterval = Interval.fromISO(`${activity.start}/${activity.end}`)

        if (!timebuckets[startISO]) {
          const overlapping = this.realTimebuckets.find(tb => {
            tb.overlaps(activityInterval)
          })
          if (overlapping) {
            console.error(`isOverlapping`) // eslint-disable-line no-console
          } else {
            const intersect = this.realTimebuckets.find(tb => {
              return tb.intersection(activityInterval)
            })
            const timebucketKey = intersect?.start.setZone(this.selectedTimezone).startOf('hour').toISO()
            const activityIntersection = intersect.intersection(activityInterval)
            if (timebuckets[timebucketKey]) {
              timebuckets[timebucketKey].activities.push({
                ...activity,
                duration: activityIntersection.length('seconds'),
                end: activityIntersection.end.toISO(),
                start: activityIntersection.start.toISO(),
              })
            }
          }
        } else {
          const tbEnd = timebuckets[startISO].interval.end
          const actEnd = DateTime.fromISO(activity.end)
          if (tbEnd < actEnd) {
            // Previous code was not considering an activity can span multiple realTimebuckets
            // Get the realtimeIntervals the activity is spanning across
            // for each of the that intervals intersect it with the activity to get the exact time span that activity happened
            // use the start end and duration of that intersection to draw the timeline
            const timeIntervalsInwhichCurrentActivityTakesPlace = this.realTimebuckets.filter(fi => fi.overlaps(activityInterval))
            for (const interval of timeIntervalsInwhichCurrentActivityTakesPlace) {
              const timebucketKey = interval.start.setZone(this.selectedTimezone).startOf('hour').toISO()
              const activityIntersection = interval.intersection(activityInterval)
              if (timebuckets[timebucketKey]) {
                timebuckets[timebucketKey].activities.push({
                  ...activity,
                  duration: activityIntersection.length('seconds'),
                  end: activityIntersection.end.toISO(),
                  start: activityIntersection.start.toISO(),
                })
              }
            }
          } else {
            timebuckets[startISO].activities.push(activity)
          }
        }
      })
      const timelines = Object.keys(timebuckets).map(key => timebuckets[key])
      if (this.filterEmpty) {
        return timelines.filter(f => f.activities.length > 0)
      }
      return timelines
    },
    dropDownSelectors() {
      return [
        {
          id: 'absolute',
          label: 'absolute',
          include: true,
        },
        {
          id: 'relative',
          label: 'relative',
          include: false,
        },
      ]
    },
    message() {
      let message = ''
      if (!this.isSingleAssetView) {
        message = this.$tc('messages.incompatibleDashboardType')
      } else {
        const dimensionName = this.$t('activity')
        message = this.$t('messages.noDimensionData', { dimension: dimensionName })
      }
      return message
    },
  },
  methods: {
    toggleFilterEmpty() {
      this.filterEmpty = !this.filterEmpty
    },
    toggleShowDetails() {
      this.showDetails = !this.showDetails
    },
    selectViewMode(mode) {
      this.viewMode = mode
    },
    updateDropDownSelection(v) {
      this.dropDownOptionSelected = v
    },
    tooltip(indicator) {
      return {
        content: this.toolTipContent(indicator),
        placement: 'top',
        classes: 'dashboard',
        trigger: 'hover',
      }
    },
    toolTipContent(mode) {
      return this.$t('timelinechart.' + mode)
    },
  },
  watch: {
    isSelectedTimeRangeOneDay: {
      handler(truthy) {
        if (!truthy && this.viewMode === 'table') {
          this.viewMode = 'timeline'
        }
      },
    },
  },
  apollo: {
    activities: {
      query: ASSET_ACTIVITIES_QUERY,
      variables() {
        return {
          from_ts: this.selectedTimeframeParam.start,
          till_ts: this.selectedTimeframeParam.end,
          assetIds: this.assetIds,
          shifts: this.selectedTimeframeParam.shifts,
        }
      },
      skip() {
        return this.assetIds.length < 1 || !this.selectedInterval || !this.isSingleAssetView
      },
    },
  },
}
</script>
