var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ActivityTypeStyled', {
    attrs: {
      "percent": _vm.activity.percent,
      "color": _vm.color,
      "type": _vm.activity.type
    }
  }, [_vm.activity.percent > 0.2 ? [_vm._v(" " + _vm._s(_vm._f("numberLocalized")(_vm._f("round")(_vm.duration.val, true))) + " " + _vm._s(_vm.duration.unit) + " ")] : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }